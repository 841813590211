const initState = {
  isAuthenticated: false,
  token: "",
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        isAuthenticated: action.payload.isAuthenticated,
        token: action.payload.token,
      };
    case "LOGOUT":
      return {
        ...initState,
      };
    default:
      return state;
  }
};

export default authReducer;
