export const login = (isAuthenticated, token) => {
  return (dispatch) => {
    dispatch({
      type: "LOGIN",
      payload: {
        isAuthenticated,
        token,
      },
    });
  };
};
export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT",
      payload: {},
    });
  };
};
