const app_version = "1.0";

const api_url = "https://pandorapi.spada.id/api";
const api_token = "eFNaMnlzMno0THNiOVxiUnNXVVI6YlJtMzVjV0tqRTRaVUZTSnlxaUg=";
const fetch_interval = 1000; //IN MILLISECONDS
const fetch_interval_mod = 1; //IN SECONDS
const fetch_attempt_limit = 20; //10 times attempt at .5s = 5 seconds before giving up and declaring network error !! BE MINDFUL THAT FOR MULTIPLE FETCHES, IT STACKS, SO ONLY CHECK THE ATTEMPTS ON ONE MAIN FUNCTION

const api_img_url = "https://pandorapi.spada.id/storage/uploads/";

//DON'T FORGET TO ADJUST THE API SETTINGS REGARDING SC AND TAX TOO! ON THE API IT'S ON TABLECONTROLLER
const service_charge = 10;
const tax = 10;

const minimum_order = 100000;

const exit_passcode = "00000000"; //passcode to exit

global.testvariable = 123;

export const Config = {
  APP_VERSION: app_version,
  API_URL: api_url,
  API_TOKEN: api_token,
  FETCH_INTERVAL: fetch_interval,
  FETCH_INTERVAL_MOD: fetch_interval_mod,
  FETCH_ATTEMPT_LIMIT: fetch_attempt_limit,
  API_IMG_URL: api_img_url,
  SERVICE_CHARGE: service_charge,
  TAX: tax,
  MINIMUM_ORDER: minimum_order,
  EXIT_PASSCODE: exit_passcode,
};
