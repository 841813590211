import { FETCH_USER_SUCCESS } from "../actions/userActions";
const initState = {
  data: {
    id_user: 0,
    username: "",
    fullname: "",
    email: "",
    deleted: 0,
    vars: {},
    token: "",
  },
};

const userReducer = (state = initState, action) => {
  if (action.type === FETCH_USER_SUCCESS) {
    if (action.payload === "reset") {
      return initState;
    } else {
      return {
        ...state,
        data: action.payload,
      };
    }
  }
  return state;
};

export default userReducer;
