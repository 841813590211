import authReducer from "./devLoginReducer";
import loginReducer from "./loginReducer";
import userReducer from "./userReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  // productData: productReducer,
  // wishlistData: wishlistReducer,
  // cartData: cartReducer,
  auth: authReducer,
  loginData: loginReducer,
  userData: userReducer,
});

export default rootReducer;
