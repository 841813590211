export const SAVE_LOGIN = "SAVE_LOGIN";

export const saveLogin = (id_user) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_LOGIN,
      payload: {
        id_user: id_user,
      },
    });
  };
};
