import React, { Component } from "react";
import styled from "styled-components";

import Barcode from "react-barcode";
import QRCode from "react-qr-code";
import moment from "moment";

class PrintLabel extends Component {
  constructor(props) {
    super(props);

    if (this.props.label !== undefined) {
      this.state = this.props.label;
      // this.state.nokoli = this.props.nokoli;
      this.state.allowdisp = true;
    } else {
      this.state = { allowdisp: false };
    }

    // this.state = {
    //     allowdisp: true,
    //     customername: "Adeline Devina",
    //     customerphone: "08112345678",
    //     customeraddress: "Jl. Wisma Sari XVI no. 9, Kec. Ngaliyan, Kota Semarang, Jawa Tengah",
    //     invoice_no: "S-TP-00000232 (INV/20210713/MPL/1404828096)",
    //     total_items: 5,
    //     total_koli: 2,
    //     courier: "JNE JNE Trucking",
    //     resi: "A92183901839012",
    //     koli: [
    //       {
    //         package: 2,
    //         items:[
    //           {
    //             qty: 2,
    //             name: "Produk A",
    //           },
    //           {
    //             qty: 1,
    //             name: "Produk B",
    //           },
    //         ]
    //       },
    //       {
    //         package: 1,
    //         items:[
    //           {
    //             qty: 5,
    //             name: "Produk C",
    //           },
    //         ]
    //       },
    //       {
    //         package: 1,
    //         items:[
    //           {
    //             qty: 1,
    //             name: "Produk D",
    //           },
    //           {
    //             qty: 5,
    //             name: "Produk X",
    //           },
    //         ]
    //       },
    //       {
    //         package: 1,
    //         items:[
    //           {
    //             qty: 2,
    //             name: "Produk D",
    //           },
    //           {
    //             qty: 2,
    //             name: "Produk F",
    //           },
    //         ]
    //       }
    //     ],
    // }
  }

  renderKoli() {
    if (this.state.koli.length > 0) {
      let i;

      let dispbox = [];

      for (i = 0; i < this.state.koli.length; i += 2) {
        let leftkoli = this.state.koli[i].items.map((dt) => {
          return <TableRegularText>{dt.qty + "x " + dt.name}</TableRegularText>;
        });

        if (this.state.koli[i + 1] !== undefined) {
          let rightkoli = this.state.koli[i + 1].items.map((dt) => {
            return (
              <TableRegularText>{dt.qty + "x " + dt.name}</TableRegularText>
            );
          });

          dispbox.push(
            <TableRow>
              <TableCol>
                <TableBoldText>
                  Koli {i + 1}{" "}
                  {this.state.koli[i].package > 1
                    ? "(aktual: " + this.state.koli[i].package + " koli)"
                    : ""}
                </TableBoldText>
                {leftkoli}
              </TableCol>
              <TableCol>
                <TableBoldText>Koli {i + 2}</TableBoldText>
                {rightkoli}
              </TableCol>
            </TableRow>
          );
        } else {
          dispbox.push(
            <TableRow>
              <TableCol>
                <TableBoldText>Koli {i + 1}</TableBoldText>
                {leftkoli}
              </TableCol>
              <TableCol></TableCol>
            </TableRow>
          );
        }
      }

      return dispbox;

      //return disp;
    }
  }

  render() {
    return (
      <OuterWrap>
        <Wrapper>
          {this.state.allowdisp && 1 == 2 && (
            <>
              <Header>
                <HeaderLeft>
                  <BoldText>{this.state.no_production.substring(5)}</BoldText>
                  <HeaderText>{this.state.packages.name}</HeaderText>
                </HeaderLeft>
                <HeaderRight>
                  <BoldText>
                    
                  </BoldText>
                  {/*<Barcode value={this.state.no_production} height="50" fontSize="10" />*/}
                </HeaderRight>
              </Header>
              <Courier>
                {
                  this.state.production_goods_details.map((dt) => {
                    return (<RegularText>{dt.quantity} {dt.goods_name}</RegularText>)
                  })
                }
              </Courier>
              <Footer>
                <FooterLeft>
                  <BoldText>{this.state.due_date.substring(0, 10)} - {this.state.packages.delivery_type}</BoldText>
                </FooterLeft>
              </Footer>
            </>
          )}
          
          {this.state.allowdisp && 1 == 1 && 
            <>
              {
                [1].map((dt, idx) => {
                  return (
                    <Container className={idx === (1 - 1) ? "last" : ""}>
                      <LabelTop>
                        <LabelTopLeft>
                          <SPOText>{this.state.spo.no_spo}</SPOText>
                          <NameText>{this.state.packages.name}</NameText>
                          {
                            this.state.production_goods_details.map((dt) => {
                              return (<ItemText>{dt.quantity} {dt.goods_name}</ItemText>)
                            })
                          }
                        </LabelTopLeft>
                        <LabelTopRight>
                          <QRText>
                            <QRCode
                            size={8}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={"https://courier.pandor.spada.id/?delivery=" + this.state.delivery_qr}
                            viewBox={`0 0 0 0`}
                            />
                          </QRText>
                        </LabelTopRight>
                      </LabelTop>
                      <LabelBottom>
                        <LBText>{this.state.due_date.substring(0, 10)} - {this.state.packages.delivery_type}</LBText>
                      </LabelBottom>
                    </Container>
                  )
                })
              }
            </>
          }
        </Wrapper>
      </OuterWrap>
    );
  }
}

const OuterWrap = styled.div``;
const Wrapper = styled.div`
  display: block;
  position: relative;
  /*width: 14cm;*/
  width: 5cm;
  height: 2.5cm;
  margin-top: 0.15cm;
  margin-left: 0cm;
  /*border: 2px solid black;*/

  padding: 5mm;
  padding-bottom: 0;

  font-family: Helvetica, Arial, sans-serif;
  font-size: 10px;
  line-height: 1em;

  /*@page {
    size: landscape;
  }*/
`;

const Container = styled.div`
  display:block;
  position: relative;
  width: 5cm;
  height: 2.5cm;
  margin-top: 0cm;
  /*margin-bottom: 0.875cm;*/
  margin-bottom: 0;
  
  &.last {
    /*margin-bottom:0.1cm;*/
    margin-bottom:0cm;
  }
`;

const LabelTop = styled.div`
  display:block;
  position: relative;
  width: 100%;
  height: 90%;
  
`;

const LabelTopLeft = styled.div`
  display:block;
  position: relative;
  float:left;
  width: 60%;
  height: 100%;
  
`;

const SPOText = styled.div`
  display:block;
  width:100%;
  
  text-decoration: underline;
  font-size: 18px;
  padding-top:1mm;
`;

const NameText = styled.div`
  display:block;
  width:100%;
  
  font-size: 13px;
  padding-top: 2mm;
  padding-bottom: 1mm;
`;

const ItemText = styled.div`
  display:block;
  width:100%;
  
  font-size: 6px;
`;

const LabelTopRight = styled.div`
  display:block;
  position: relative;
  float:left;
  width: 40%;
  height: 100%;
`;

const QRText = styled.div`
  display:block;
  position: relative;
  text-align:left;
  font-size: 10px;
  padding-top: 1mm;
`;

const LabelBottom = styled.div`
  display:block;
  position: relative;
  width: 100%;
  height: 10%;
  
`;

const LBText = styled.div`
  padding-top:2mm;
  font-size: 12px;
`;

const Header = styled.div`
  display: block;
  position: relative;
  width: 100%;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const HeaderLeft = styled.div`
  display: block;
  position: relative;
  width: 45%;
  float: left;
`;

const HeaderLogo = styled.img`
  display: block;
  position: relative;
  width: 45%;
  float:left;
  margin: auto;
  margin-bottom: 1mm;
`;

const HeaderRight = styled.div`
  display: block;
  position: relative;
  width: 72%;
  float: left;
`;

const HeaderText = styled.p`
  text-align: center;
  margin-bottom: 1mm;
`;

const Recipient = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-top: 3mm;
  border-bottom: 1px solid black;
`;

const RecipientRow = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 3mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const RecipientLeft = styled.div`
  display: block;
  position: relative;
  width: 23%;
  float: left;

  &:after {
    position: absolute;
    content: ":";
    right: 0;
  }
`;

const RecipientRightLarge = styled.div`
  display: block;
  position: relative;
  width: 71%;
  padding-left: 5%;
  float: left;
  font-size: 1.3em;
  font-weight: bold;
`;

const RecipientRight = styled.div`
  display: block;
  position: relative;
  width: 71%;
  padding-left: 5%;
  float: left;
`;

const TextRow = styled.div`
  display: block;
  position: relative;
  width: 100%;
  font-weight: bold;
`;

const Totals = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding-top: 3mm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const TotalLeft = styled.div`
  display: block;
  position: relative;
  width: 70%;
  float: left;
`;

const TotalRight = styled.div`
  display: block;
  position: relative;
  width: 30%;
  float: right;
`;

const TableContents = styled.table`
  display: table;
  position: relative;
  width: 100%;
  border-spacing: 0;
  margin-top: 1mm;
  font-size: 11px;
`;

const TableRow = styled.tr`
  display: table-row;
  position: relative;
  height: 25mm;
  width: 100%;
`;

const TableCol = styled.td`
  display: table-cell;
  position: relative;
  width: 48%;
  padding: 1%;
  vertical-align: top;
  margin: 0;
  border: 1px solid black;
  border-spacing: 0;
  font-size: 0.9em;
`;

const TableBoldText = styled.div`
  font-weight: bold;
  padding: 0;
  margin: 0;
  font-size: 0.9em !important;
`;

const TableRegularText = styled.div`
  padding: 0;
  margin: 2mm 1mm;
  font-size: 0.9em !important;
`;

const BoldText = styled.div`
  font-weight: bold;
  padding: 0;
  margin: 0;

  &.nokoli {
    font-size: 2em;
    font-weight: bold;
    margin-left: 5mm;
    margin-top: 5mm;
  }
`;

const RegularText = styled.div`
  padding: 0;
  margin: 2mm 1mm;
`;

const Courier = styled.div`
  display: block;
  position: relative;
  width: 100%;
`;

const Footer = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  width: 92%;
  height: 1cm;

  /*CLEARFIX*/
  overflow: auto;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

const FooterLeft = styled.div`
  display: block;
  position: relative;
  width: 100%;
  float: left;

  height: 10mm;

  border-right: 1px solid black;
`;

const FooterRight = styled.div`
  display: block;
  position: relative;
  width: 25%;
  float: left;
`;

export default PrintLabel;
